import React, { PropsWithChildren } from 'react'
import { Typography } from './Typography'

export const CallToAction = ({ children }: PropsWithChildren) => {
  return (
    <section className="flex flex-col items-center bg-call-to-action px-10 py-14 max-sm:pb-32">
      <Typography.H3 className="mb-5 text-white">
        Learn how ReturnPro can transform your business
      </Typography.H3>
      {children}
    </section>
  )
}
